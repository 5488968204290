import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import './GeneNetwork.css';

cytoscape.use(fcose);

const categoriesList = [
  'antibodies',
  'small molecules',
  'proteins',
  'peptides',
  'cell therapies',
  'gene therapies',
  'nucleic acids',
  'other'
];

const getRandomCategory = () => categoriesList[Math.floor(Math.random() * categoriesList.length)];


const geneDescriptions = {
  'BRCA1': 'BRCA1 is a human tumor suppressor gene and is responsible for repairing DNA.',
  'TP53': 'TP53 is a gene that codes for a protein that regulates the cell cycle.',
  'EGFR': 'EGFR is a gene that provides instructions for making a protein involved in cell signaling.',
  // Add more gene descriptions as needed
};

const proteinDescriptions = {
  'Albumin': 'Albumin is a protein made by the liver that keeps fluid in your bloodstream.',
  'Hemoglobin': 'Hemoglobin is a protein in red blood cells that carries oxygen.',
  'Insulin': 'Insulin is a hormone made by the pancreas that allows your body to use sugar.',
  // Add more protein descriptions as needed
};

const edgeColors = ['#FF0000', '#00FF00']; // Red, Green

const Graph = forwardRef(({ selectedGene, newNodes }, ref) => {
  const cyContainer = useRef(null);
  const [cy, setCy] = useState(null);
  const [popups, setPopups] = useState([]);
  const [filters, setFilters] = useState({
    druggability: 0,
    safety: 0,
    specificity: 0,
    novelty: 0
  });
  const [selectedCategories, setSelectedCategories] = useState({
    antibodies: true,
    'small molecules': true,
    proteins: true,
    peptides: true,
    'cell therapies': true,
    'gene therapies': true,
    'nucleic acids': true,
    other: true,
  });
  const [topScores, setTopScores] = useState([]);
  const [modalContent, setModalContent] = useState({ show: false, title: '', nodeId: '' });

  useImperativeHandle(ref, () => ({
    getCy: () => cy
  }));

  useEffect(() => {
    if (cyContainer.current) {
      const getRandomScores = () => ({
        druggability: Math.random() * 2,
        safety: Math.random() * 2,
        specificity: Math.random() * 2,
        novelty: Math.random() * 2,
      });

      const genes = [
        'IL6', 'ESR1', 'AKT1', 'ACE', 'FTO', 'CYP2D6', 'MMP9', 'GSTP1', 'PTEN', 'CYP1A1',
        'IL10', 'HIF1A', 'MTHFR2', 'ERBB2', 'AR', 'CDH1', 'IGF1', 'ESR2', 'CYP3A4', 'CYP3A5',
        'CFTR', 'GSTT1', 'COMT', 'BRAF', 'KRAS', 'NRAS', 'PIK3CA', 'PTPN11', 'AKT2', 'AKT3',
        'CTNNB1', 'GATA3', 'MAPK1', 'BRCA2', 'PALB2', 'RAD51', 'CHEK2', 'ATM', 'BARD1', 'BRIP1',
        'CDK12', 'FANCA', 'FANCD2', 'FANCI', 'FANCL', 'MRE11', 'NBN', 'RAD50', 'RAD52', 'RAD54L',
        'XRCC2', 'XRCC3', 'NPM1', 'MYC', 'CCND1', 'RB1', 'MDM2', 'MDM4', 'CDKN2A', 'CDKN2B',
        'SMAD4', 'SMAD2', 'SMAD3', 'TGFBR1', 'TGFBR2', 'NOTCH1', 'NOTCH2', 'NOTCH3', 'FBXW7',
        'PTCH1', 'PTCH2', 'SMO', 'GLI1', 'GLI2', 'GLI3', 'WT1', 'WT2', 'MEN1', 'RET', 'NF1',
        'NF2', 'TSC1', 'TSC2', 'VHL', 'MET', 'HGF', 'FLT1', 'FLT3', 'PDGFRA', 'PDGFRB', 'KIT',
        'KDR', 'FGFR1', 'FGFR2', 'FGFR3', 'FGFR4', 'NTRK1', 'NTRK2', 'NTRK3', 'ALK', 'ROS1',
        'RET', 'ERBB3', 'ERBB4', 'IGF1R', 'IGF2R', 'INSR', 'EGFR', 'VEGFA', 'MTHFR', 'APOE',
        'TNF', 'MAP2K1', 'MAP2K2', 'MAPK3', 'MAPK14', 'JAK1', 'JAK2', 'JAK3',
        'STAT1', 'STAT2', 'STAT3', 'STAT4', 'STAT5A', 'STAT5B', 'STAT6', 'FOXO1', 'FOXO3',
        'FOXO4', 'FOXO6', 'GATA1', 'GATA2', 'GATA4', 'GATA5', 'GATA6', 'HOXA1', 'HOXA2',
        'HOXA3', 'HOXA4', 'HOXA5', 'HOXA6', 'HOXA7', 'HOXA9', 'HOXB1', 'HOXB2', 'HOXB3',
        'HOXB4', 'HOXB5', 'HOXB6', 'HOXB7', 'HOXB8', 'HOXB9', 'HOXC4', 'HOXC5', 'HOXC6',
        'HOXC8', 'HOXC9', 'HOXD1', 'HOXD3', 'HOXD4', 'HOXD8', 'HOXD9', 'HOXD10', 'HOXD11',
        'HOXD12', 'HOXD13', 'PAX3', 'PAX5', 'PAX6', 'PAX7', 'PAX8', 'PAX9', 'PAX2', 'PAX4',
        'PAX1', 'SOX1', 'SOX2', 'SOX3', 'SOX4', 'SOX5', 'SOX6', 'SOX7', 'SOX8', 'SOX9', 'SOX10',
        'ABL1', 'ABL2', 'ACVR1', 'ACVR2A', 'ACVR2B', 'ADCY1', 'ADCY2', 'ADCY3', 'ADCY4', 'ADCY5',
        'ADCY6', 'ADCY7', 'ADCY8', 'ADCY9', 'ADRB1', 'ADRB2', 'ADRB3', 'AKT1S1', 'AKTIP', 'ALK',
        'ALOX12', 'ALOX15', 'ALOX5', 'ALOX5AP', 'AMPH', 'ANGPT1', 'ANGPT2', 'ANGPTL1', 'ANGPTL2',
        'ANGPTL3', 'ANGPTL4', 'ANGPTL5', 'ANGPTL6', 'ANGPTL7', 'ANKRD1', 'ANKRD11', 'ANKRD12',
        'ANKRD13A', 'ANKRD13B', 'ANKRD13C', 'ANKRD13D', 'ANKRD16', 'ANKRD17', 'ANKRD2', 'ANKRD22',
        'ANKRD23', 'ANKRD24', 'ANKRD26', 'ANKRD27', 'ANKRD28', 'ANKRD29', 'ANKRD30A', 'ANKRD30B',
        'ANKRD31', 'ANKRD32', 'ANKRD33', 'ANKRD33B', 'ANKRD34A', 'ANKRD34B', 'ANKRD34C', 'ANKRD35',
        'ANKRD36', 'ANKRD36BP1', 'ANKRD36BP2', 'ANKRD37', 'ANKRD38', 'ANKRD39', 'ANKRD40', 'ANKRD41',
        'ANKRD42', 'ANKRD43', 'ANKRD44', 'ANKRD45', 'ANKRD46', 'ANKRD47', 'ANKRD49', 'ANKRD50',
        'ANKRD52', 'ANKRD53', 'ANKRD54', 'ANKRD55', 'ANKRD56', 'ANKRD57', 'ANKRD58', 'ANKRD59',
        'ANKRD60', 'ANKRD61', 'ANKRD62', 'ANKRD63', 'ANKRD64', 'ANKRD65', 'ANKRD66', 'ANKRD67'
      ];

      const proteins = [
        'Hemoglobin', 'Myosin', 'Collagen', 'Elastin', 'Fibronectin', 'Keratin', 'Tubulin',
        'Vimentin', 'Desmin', 'Troponin', 'Thrombin', 'Fibrinogen', 'Actin', 'Albumin',
        'Globulin', 'Fibrin', 'Myoglobin', 'Laminin', 'Osteonectin', 'Integrin', 'Cadherin',
        'Selectin', 'Proteoglycan', 'Glycoprotein', 'Polymerase', 'Helicase', 'Ligase', 'Nuclease',
        'Topoisomerase', 'Ribonuclease', 'Peptidase', 'Phosphatase', 'Kinase', 'Transferase',
        'Isomerase', 'Mutase', 'Synthetase', 'Synthase', 'Oxidase', 'Reductase', 'Hydrolase',
        'Lyase', 'Dehydrogenase', 'Deaminase', 'Decarboxylase', 'Hydratase', 'Lysozyme',
        'Endonuclease', 'Exonuclease', 'Transaminase', 'Aminotransferase', 'Glycosyltransferase',
        'Ubiquitin', 'Sumo', 'Histone', 'Chromatin'
      ];

      const elements = [];

      genes.forEach((gene, index) => {
        const scores = getRandomScores();
        const category = getRandomCategory();
        elements.push({
          data: {
            id: gene,
            label: gene,
            type: 'gene',
            category: category,
            ...scores,
            avgScore: (scores.druggability + scores.safety + scores.specificity + scores.novelty) / 4
          }
        });

        const protein = proteins[index % proteins.length];
        elements.push({
          data: {
            id: `${gene}-${protein}`,
            source: gene,
            target: protein,
            c: Math.random(),
            color: edgeColors[Math.floor(Math.random() * edgeColors.length)]
          }
        });
      });

      proteins.forEach(protein => {
        const scores = getRandomScores();
        const category = getRandomCategory();
        elements.push({
          data: {
            id: protein,
            label: protein,
            type: 'protein',
            category: category,
            ...scores,
            avgScore: (scores.druggability + scores.safety + scores.specificity + scores.novelty) / 4
          }
        });
      });

      const cyInstance = cytoscape({
        container: cyContainer.current,
        elements: elements,
        style: [
          {
            selector: 'node[type="gene"]',
            style: {
              'background-color': 'mapData(avgScore, 0, 1, #E6F7FF, #6A69FB)',
              'label': 'data(label)',
              'width': 40,
              'height': 40
            }
          },
          {
            selector: 'node[type="protein"]',
            style: {
              'background-color': 'mapData(avgScore, 0, 1, #FFE6E6, #B092B8)',
              'label': 'data(label)',
              'width': 40,
              'height': 40
            }
          },
          {
            selector: 'edge',
            style: {
              'width': 2,
              'line-color': 'data(color)',
              'target-arrow-color': 'data(color)',
              'target-arrow-shape': 'triangle'
            }
          },
          {
            selector: 'node.new-node[type="gene"]',
            style: {
              'background-color': 'mapData(avgScore, 0, 1, #E6F7FF, #6A69FB)',
              'border-color': '#000',
              'border-width': 4,
              'label': 'data(label)',
              'width': 40,
              'height': 40
            }
          },
          {
            selector: 'node.new-node[type="protein"]',
            style: {
              'background-color': 'mapData(avgScore, 0, 1, #FFE6E6, #B092B8)',
              'border-color': '#000',
              'border-width': 4,
              'label': 'data(label)',
              'width': 40,
              'height': 40
            }
          },
          {
            selector: 'node.selected',
            style: {
              'width': 60,
              'height': 60,
              'border-color': '#FFD700',
              'border-width': 4
            }
          }
        ]
      });

      cyInstance.on('tap', 'node', (event) => {
        const node = event.target;
        const nodeId = node.id();

        setPopups(prevPopups => {
          const existingPopupIndex = prevPopups.findIndex(popup => popup.id === nodeId);

          if (existingPopupIndex !== -1) {
            // Remove popup if it exists
            node.removeClass('selected');
            return prevPopups.filter(popup => popup.id !== nodeId);
          } else {
            // Add new popup
            node.addClass('selected');
            const newPopup = {
              id: nodeId,
              label: node.data('label'),
              description: node.data('type') === 'gene' ? geneDescriptions[nodeId] : proteinDescriptions[nodeId],
              druggability: node.data('druggability'),
              safety: node.data('safety'),
              specificity: node.data('specificity'),
              novelty: node.data('novelty'),
              position: node.renderedPosition()
            };
            return [...prevPopups, newPopup];
          }
        });
      });

      setCy(cyInstance);

      return () => {
        cyInstance.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (cy) {
      const updatePopupPosition = () => {
        setPopups(prevPopups => prevPopups.map(popup => {
          const node = cy.getElementById(popup.id);
          return {
            ...popup,
            position: node.renderedPosition()
          };
        }));
      };

      cy.on('zoom pan', updatePopupPosition);
      cy.on('position', 'node', updatePopupPosition);

      return () => {
        cy.off('zoom pan', updatePopupPosition);
        cy.off('position', 'node', updatePopupPosition);
      };
    }
  }, [cy, popups]);

  useEffect(() => {
    if (cy) {
      cy.nodes().forEach(node => {
        const data = node.data();
        const showNode = (
          data.druggability >= filters.druggability &&
          data.safety >= filters.safety &&
          data.specificity >= filters.specificity &&
          data.novelty >= filters.novelty &&
          selectedCategories[data.category]
        );
        node.style('display', showNode ? 'element' : 'none');
      });

      // Reform the layout after filtering
      cy.layout({
        name: 'fcose',
        quality: 'default',
        randomize: false,
        animate: true,
        animationDuration: 1000,
        fit: true,
        padding: 100,
        nodeRepulsion: 200000,
        idealEdgeLength: 100,
        edgeElasticity: 0.45,
        nestingFactor: 0.1,
        gravity: 0.25,
        numIter: 2500,
        tile: true,
        tilingPaddingVertical: 10,
        tilingPaddingHorizontal: 10,
        tilingCompareBy: 'degree'
      }).run();

      // Update top scores
      const allNodes = cy.nodes().map(node => ({
        id: node.id(),
        label: node.data('label'),
        avgScore: node.data('avgScore')
      }));

      const sortedNodes = allNodes.sort((a, b) => b.avgScore - a.avgScore);
      const top5 = sortedNodes.slice(0, 5);
      setTopScores(top5);
    }
  }, [filters, selectedCategories, cy]);

  useEffect(() => {
    if (cy && newNodes) {
      const elementsToAdd = [];

      // First add all nodes
      newNodes.forEach(node => {
        const {
          gene, protein, druggability, safety, specificity, novelty
        } = node;

        const id = gene || protein;
        const type = gene ? 'gene' : 'protein';
        const description = gene ? geneDescriptions[gene] : proteinDescriptions[protein];
        const avgScore = (parseFloat(druggability) + parseFloat(safety) + parseFloat(specificity) + parseFloat(novelty)) / 4;
        const category = getRandomCategory();
        elementsToAdd.push({
          data: {
            id,
            label: id,
            type,
            category: category,
            druggability: parseFloat(druggability),
            safety: parseFloat(safety),
            specificity: parseFloat(specificity),
            novelty: parseFloat(novelty),
            description,
            avgScore
          },
          classes: 'new-node' // Add this class to new nodes
        });
      });

      // Then add all edges
      newNodes.forEach(node => {
        const { gene, protein, connections } = node;
        const id = gene || protein;

        if (connections) {
          const connectionsArray = connections.split(',');
          connectionsArray.forEach(target => {
            elementsToAdd.push({
              data: {
                id: `${id}-${target.trim()}`,
                source: id,
                target: target.trim(),
                c: Math.random(),
                color: edgeColors[Math.floor(Math.random() * edgeColors.length)]
              }
            });
          });
        }
      });

      cy.add(elementsToAdd);
      cy.layout({
        name: 'fcose',
        quality: 'default',
        randomize: false,
        animate: true,
        animationDuration: 1000,
        fit: true,
        padding: 100,
        nodeRepulsion: 200000,
        idealEdgeLength: 100,
        edgeElasticity: 0.45,
        nestingFactor: 0.1,
        gravity: 0.25,
        numIter: 2500,
        tile: true,
        tilingPaddingVertical: 10,
        tilingPaddingHorizontal: 10,
        tilingCompareBy: 'degree'
      }).run();

      // Update top scores
      const allNodes = cy.nodes().map(node => ({
        id: node.id(),
        label: node.data('label'),
        avgScore: node.data('avgScore')
      }));

      const sortedNodes = allNodes.sort((a, b) => b.avgScore - a.avgScore);
      const top5 = sortedNodes.slice(0, 5);
      setTopScores(top5);
    }
  }, [cy, newNodes]);

  const handleSliderChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: parseFloat(value)
    }));
  };

  const handleCategoryChange = (event) => {
    const { name, checked } = event.target;
    setSelectedCategories(prevCategories => ({
      ...prevCategories,
      [name]: checked
    }));
  };

  const openModal = (nodeId) => {
    setModalContent({ show: true, title: `${nodeId} Binding Proteins`, nodeId });
  };

  const closeModal = () => {
    setModalContent({ show: false, title: '', nodeId: '' });
  };

  const images = Array.from({ length: 12 }, (_, index) => `/images/binder${index + 1}.png`);

  return (
    <div id="graph-container" style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div ref={cyContainer} style={{ width: '100%', height: '100%' }} />
      <div className="sliders">
        <div className="flex flex-col text-sm">
          <label className='font-medium text-gray-700'>Druggability: {filters.druggability}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            name="druggability"
            value={filters.druggability}
            onChange={handleSliderChange}
            style={{ accentColor: '#6A69FB' }}
          />
        </div>
        <div className="flex flex-col text-sm">
          <label className='font-medium text-gray-700'>Safety: {filters.safety}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            name="safety"
            value={filters.safety}
            onChange={handleSliderChange}
            style={{ accentColor: '#6A69FB' }}
          />
        </div>
        <div className="flex flex-col text-sm">
          <label className='font-medium text-gray-700'>Specificity: {filters.specificity}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            name="specificity"
            value={filters.specificity}
            onChange={handleSliderChange}
            style={{ accentColor: '#6A69FB' }}
          />
        </div>
        <div className="flex flex-col text-sm">
          <label className='font-medium text-gray-700'>Novelty: {filters.novelty}</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            name="novelty"
            value={filters.novelty}
            onChange={handleSliderChange}
            style={{ accentColor: '#6A69FB' }}
          />
        </div>
      </div>
      <div class="categories">
      <fieldset className="space-y-1">
      {Object.keys(selectedCategories).map(category =>
          <div className="relative flex items-start">
          <div className="flex items-center h-5" key={category}>
            <input
              id={category}
              aria-describedby="comments-description"
              name={category}
              type="checkbox"
              checked={selectedCategories[category]}
              onChange={handleCategoryChange}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={category} className="font-medium text-gray-700">
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </label>
          </div>
        </div>
        )}
    </fieldset>
    </div>
      <div className="legend font-medium text-gray-700 text-sm">
        <div>
          <span style={{ color: '#FF0000' }}>■</span> Upregulated
        </div>
        <div><span style={{ color: '#00FF00' }}>■</span> Downregulated</div>
        <div><span style={{ color: '#6A69FB' }}>■</span> Gene</div>
        <div><span style={{ color: '#B092B8' }}>■</span> Protein</div>
      </div>
      <div className="top-scores font-medium text-gray-700 text-sm">
        <strong>Validated Targets:</strong>
        <table>
          <tbody>
            <tr key={0}>
              <td><b>Node</b></td>
              <td><b>Score</b></td>
            </tr>
            {topScores.map((node, index) => (
              <tr key={index + 1}>
                <td>{node.label}</td>
                <td>{node.avgScore.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {popups.map(popup => (
        <div
          key={popup.id}
          style={{
            position: 'absolute',
            left: popup.position.x + 20,
            top: popup.position.y + 20,
            background: 'white',
            border: '1px solid black',
            padding: '5px',
            zIndex: 10,
            transform: `scale(${cy ? cy.zoom() : 1})`,
            transformOrigin: 'top left',
            width: '200px'
          }}
        >
          <strong>{popup.label}</strong>
          <p>{popup.description}</p>
          <p>Druggability: {popup.druggability.toFixed(2)}</p>
          <p>Safety: {popup.safety.toFixed(2)}</p>
          <p>Specificity: {popup.specificity.toFixed(2)}</p>
          <p>Novelty: {popup.novelty.toFixed(2)}</p>
          <button
            className="bg-indigo-500 text-white mt-2 py-1 px-0.5 rounded"
            onClick={() => openModal(popup.id)}
          >
            Generate binding proteins
          </button>
        </div>
      ))}

{modalContent.show && (
  <div className="fixed z-50 inset-0 flex items-center justify-center">
  <div className="bg-white rounded-lg p-6 shadow-lg relative w-full max-w-6xl overflow-hidden flex flex-col">
    {/* Close button */}
    <button
      className="absolute top-2 right-2 text-gray-700 hover:text-black"
      onClick={closeModal}
    >
      &times;
    </button>
    <h2 className="text-2xl font-bold mb-4 text-center">{modalContent.title}</h2>
    
    {/* Grid container for images */}
    <div className="flex-grow">
      <div className="grid grid-rows-2 grid-cols-6 gap-4 h-full">
        {images.map((image, index) => (
          <div
            key={index}
            className="flex items-center justify-center aspect-square overflow-hidden"
          >
            <img 
              src={image} 
              className="max-w-full max-h-full object-contain" 
              alt={`Image ${index + 1}`} 
            />
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
)}


    </div>
  );
});

export default Graph;