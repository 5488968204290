import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import SearchComponent from './components/Search';
import Graph from './components/GeneNetwork';
import Login from './components/Login'; // Import the login component
import PrivateRoute from './components/PrivateRoute'; // Import the private route component
import './App.css';
import './index.css';

function App() {
  const graphRef = useRef(null);
  const [selectedGene, setSelectedGene] = useState(null);
  const [newNodes, setNewNodes] = useState(null);

  const handleGeneSelect = (gene) => {
    if (graphRef.current) {
      const cy = graphRef.current.getCy();
      const node = cy.getElementById(gene.id);
      if (node) {
        cy.animate({
          fit: {
            eles: node,
            padding: 250
          },
          center: {
            eles: node
          },
          duration: 1000
        });
      }
    }
  };

  const handleFileUpload = (data) => {
    setNewNodes(data);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
        <div className="homepage">
          <div id="maincontent">
              <h2>Valinor Biotechnologies</h2>
              <p><strong>At Valinor, we are using machine learning to build LSI – Longevity Superintelligence.</strong></p>
              <br></br>
              <p>LSI will be the most transformative breakthrough in biology and healthcare of all time, giving humanity the ability to identify the core, underlying mechanisms of aging, and how to successfully treat those mechanisms.</p>
              <br></br>
              <p>Thanks to advancements in multi-omics sequencing and computational capabilities, this crowning achievement of biology is achievable for the first time in history.</p>
              <br></br>
              <p>We’ve assembled an elite group of machine learning engineers and biologists who are obsessed with building LSI, and have already built models that far outperform industry heavyweights for aging-focused drug discovery.</p>
              <br></br>
              <p>Our team includes individuals with experience at labs at Harvard Medical School, the Thayer School of Engineering at Dartmouth, as well as multiple venture-backed ML startups. Our advisors include founders of bleeding-edge ML companies, business development leaders from global biotech unicorns, and heads of aging-focused research labs.</p>
              <br></br>
              <p>We are hiring! If you want to work on machine learning models that have the potential to indefinitely extend human lifespan, contact our CEO at <a href="mailto:josh@valinorbio.ai">josh@valinorbio.ai</a>.</p>
              <p class="quote">"For in Valinor they have no trial of death...and so great was their bliss that it could not be measured."<br/>- The Silmarillion</p>
          </div>
      </div>
    } />

        <Route
          path="/demo"
          element={
            <PrivateRoute element={() => (
              <div className="App">
                <div name="search-section" className="search-section flex justify-center">
                  <SearchComponent onGeneSelect={handleGeneSelect} onFileUpload={handleFileUpload} newNodes={newNodes} />
                </div>
                <div className="graph-wrapper">
                  <div className="graph-container">
                    <Graph ref={graphRef} selectedGene={selectedGene} newNodes={newNodes} />
                  </div>
                </div>
              </div>
            )} />
          }
        />

        {/* Login page route */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
