import React, { useState } from 'react';
import './Search.css';
import { FaUpload } from 'react-icons/fa';
import Papa from 'papaparse';

const SearchComponent = ({ onGeneSelect, onFileUpload, newNodes }) => {
  const [query, setQuery] = useState('');

  const genes = [
    'IL6', 'ESR1', 'AKT1', 'ACE', 'FTO', 'CYP2D6', 'MMP9', 'GSTP1', 'PTEN', 'CYP1A1',
    'IL10', 'HIF1A', 'MTHFR2', 'ERBB2', 'AR', 'CDH1', 'IGF1', 'ESR2', 'CYP3A4', 'CYP3A5',
    'CFTR', 'GSTT1', 'COMT', 'BRAF', 'KRAS', 'NRAS', 'PIK3CA', 'PTPN11', 'AKT2', 'AKT3',
    'CTNNB1', 'GATA3', 'MAPK1', 'BRCA2', 'PALB2', 'RAD51', 'CHEK2', 'ATM', 'BARD1', 'BRIP1',
    'CDK12', 'FANCA', 'FANCD2', 'FANCI', 'FANCL', 'MRE11', 'NBN', 'RAD50', 'RAD52', 'RAD54L',
    'XRCC2', 'XRCC3', 'NPM1', 'MYC', 'CCND1', 'RB1', 'MDM2', 'MDM4', 'CDKN2A', 'CDKN2B',
    'SMAD4', 'SMAD2', 'SMAD3', 'TGFBR1', 'TGFBR2', 'NOTCH1', 'NOTCH2', 'NOTCH3', 'FBXW7',
    'PTCH1', 'PTCH2', 'SMO', 'GLI1', 'GLI2', 'GLI3', 'WT1', 'WT2', 'MEN1', 'RET', 'NF1',
    'NF2', 'TSC1', 'TSC2', 'VHL', 'MET', 'HGF', 'FLT1', 'FLT3', 'PDGFRA', 'PDGFRB', 'KIT',
    'KDR', 'FGFR1', 'FGFR2', 'FGFR3', 'FGFR4', 'NTRK1', 'NTRK2', 'NTRK3', 'ALK', 'ROS1',
    'RET', 'ERBB3', 'ERBB4', 'IGF1R', 'IGF2R', 'INSR', 'EGFR', 'VEGFA', 'MTHFR', 'APOE',
    'TNF', 'MAP2K1', 'MAP2K2', 'MAPK3', 'MAPK14', 'JAK1', 'JAK2', 'JAK3',
    'STAT1', 'STAT2', 'STAT3', 'STAT4', 'STAT5A', 'STAT5B', 'STAT6', 'FOXO1', 'FOXO3',
    'FOXO4', 'FOXO6', 'GATA1', 'GATA2', 'GATA4', 'GATA5', 'GATA6', 'HOXA1', 'HOXA2',
    'HOXA3', 'HOXA4', 'HOXA5', 'HOXA6', 'HOXA7', 'HOXA9', 'HOXB1', 'HOXB2', 'HOXB3',
    'HOXB4', 'HOXB5', 'HOXB6', 'HOXB7', 'HOXB8', 'HOXB9', 'HOXC4', 'HOXC5', 'HOXC6',
    'HOXC8', 'HOXC9', 'HOXD1', 'HOXD3', 'HOXD4', 'HOXD8', 'HOXD9', 'HOXD10', 'HOXD11',
    'HOXD12', 'HOXD13', 'PAX3', 'PAX5', 'PAX6', 'PAX7', 'PAX8', 'PAX9', 'PAX2', 'PAX4',
    'PAX1', 'SOX1', 'SOX2', 'SOX3', 'SOX4', 'SOX5', 'SOX6', 'SOX7', 'SOX8', 'SOX9', 'SOX10',
    'ABL1', 'ABL2', 'ACVR1', 'ACVR2A', 'ACVR2B', 'ADCY1', 'ADCY2', 'ADCY3', 'ADCY4', 'ADCY5',
    'ADCY6', 'ADCY7', 'ADCY8', 'ADCY9', 'ADRB1', 'ADRB2', 'ADRB3', 'AKT1S1', 'AKTIP', 'ALK',
    'ALOX12', 'ALOX15', 'ALOX5', 'ALOX5AP', 'AMPH', 'ANGPT1', 'ANGPT2', 'ANGPTL1', 'ANGPTL2',
    'ANGPTL3', 'ANGPTL4', 'ANGPTL5', 'ANGPTL6', 'ANGPTL7', 'ANKRD1', 'ANKRD11', 'ANKRD12',
    'ANKRD13A', 'ANKRD13B', 'ANKRD13C', 'ANKRD13D', 'ANKRD16', 'ANKRD17', 'ANKRD2', 'ANKRD22',
    'ANKRD23', 'ANKRD24', 'ANKRD26', 'ANKRD27', 'ANKRD28', 'ANKRD29', 'ANKRD30A', 'ANKRD30B',
    'ANKRD31', 'ANKRD32', 'ANKRD33', 'ANKRD33B', 'ANKRD34A', 'ANKRD34B', 'ANKRD34C', 'ANKRD35',
    'ANKRD36', 'ANKRD36BP1', 'ANKRD36BP2', 'ANKRD37', 'ANKRD38', 'ANKRD39', 'ANKRD40', 'ANKRD41',
    'ANKRD42', 'ANKRD43', 'ANKRD44', 'ANKRD45', 'ANKRD46', 'ANKRD47', 'ANKRD49', 'ANKRD50',
    'ANKRD52', 'ANKRD53', 'ANKRD54', 'ANKRD55', 'ANKRD56', 'ANKRD57', 'ANKRD58', 'ANKRD59',
    'ANKRD60', 'ANKRD61', 'ANKRD62', 'ANKRD63', 'ANKRD64', 'ANKRD65', 'ANKRD66', 'ANKRD67'
  ];

  const proteins = [
    'Hemoglobin', 'Myosin', 'Collagen', 'Elastin', 'Fibronectin', 'Keratin', 'Tubulin',
    'Vimentin', 'Desmin', 'Troponin', 'Thrombin', 'Fibrinogen', 'Actin', 'Albumin',
    'Globulin', 'Fibrin', 'Myoglobin', 'Laminin', 'Osteonectin', 'Integrin', 'Cadherin',
    'Selectin', 'Proteoglycan', 'Glycoprotein', 'Polymerase', 'Helicase', 'Ligase', 'Nuclease',
    'Topoisomerase', 'Ribonuclease', 'Peptidase', 'Phosphatase', 'Kinase', 'Transferase',
    'Isomerase', 'Mutase', 'Synthetase', 'Synthase', 'Oxidase', 'Reductase', 'Hydrolase',
    'Lyase', 'Dehydrogenase', 'Deaminase', 'Decarboxylase', 'Hydratase', 'Lysozyme',
    'Endonuclease', 'Exonuclease', 'Transaminase', 'Aminotransferase', 'Glycosyltransferase',
    'Ubiquitin', 'Sumo', 'Histone', 'Chromatin'
  ];

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const searchQuery = query.toLowerCase();
      const matchedGene = genes.find(item => item.toLowerCase() === searchQuery);
      const matchedProtein = proteins.find(item => item.toLowerCase() === searchQuery);
      var matchedNewNode = undefined;
      if (newNodes != null) {
        const matchedItem = newNodes.find(item => 
          (item.gene && item.gene.toLowerCase() === searchQuery) ||
          (item.protein && item.protein.toLowerCase() === searchQuery)
        );

        if (matchedItem) {
          matchedNewNode = matchedItem.gene ? matchedItem.gene : matchedItem.protein;
        }
      }
      if (matchedGene || matchedNewNode || matchedProtein) {
        console.log(searchQuery);
        console.log(matchedGene, matchedNewNode, matchedProtein);
        console.log(query);
        if (matchedGene) {
          onGeneSelect({ id: matchedGene });
        } else if (matchedNewNode) {
          onGeneSelect({ id: matchedNewNode });
        } else if (matchedProtein) {
          onGeneSelect({ id: matchedProtein });
        }
        const graphContainer = document.getElementById('graph-container');
        if (graphContainer) {
          window.scrollTo({
            top: graphContainer.offsetTop,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          console.log(results.data);
          onFileUpload(results.data);
        },
      });
    }
  };

  return (
    <div className="search-container w-2/4">
      <label htmlFor="email" className="sr-only">
        Email
      </label>
      <input
        type="text"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="Start your search among known genes and proteins right here"
        value={query}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
      />
      <label htmlFor="file-upload" className="custom-file-upload">
        <FaUpload />
      </label>
      <input id="file-upload" type="file" onChange={handleFileChange} />
    </div>
  );
};

export default SearchComponent;
