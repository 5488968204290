import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Call your serverless function to check cookie validity
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/check-cookie'); // Assuming you have a serverless function to check auth
        const data = await response.json();
        if (response.status === 200 && data.message === "Access granted") {
          console.log("User is authenticated");
          console.log(response.status);
          setAuth(true);
        } else {
          console.log("User is not authenticated");
          setAuth(false);
        }
      } catch (error) {
        console.log("Error during auth check:", error);
        setAuth(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  // Show a loading state until the auth status is determined
  if (loading) {
    return <div>Loading...</div>; // You can show a spinner here if you want
  }

  // If user is authenticated, render the component, otherwise redirect to login
  return auth ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
